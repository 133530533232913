<template>
  <div class="grey lighten-5">
    <v-row style="height: auto">
      <v-col cols="12" md="3" lg="3" class="host_bac1 text-center">
        <div class="mt-15">
          <v-text class="host_abbas pa-2">
            <!-- <h4>مضيف</h4> -->

            <!-- <div class="host_logo">
          <img src="assets/img/host/logo.png" style="width:80px;height: :80px;" alt="" />
        </div> -->
            <p class="my-0"  >مضيف العتبة العباسية المقدسة </p>
          </v-text>
          <div class="d-flex justify-center">
            <img style="max-width:300px" :src="'https://alkafeel.net/src/svg/'+logo" alt="" />
          </div>
          <v-container fluid>
            <v-row align="center">
              <v-col cols="">
                <div
                  class="input-wrapper"
                  style="
                    position: relative;
                    max-width: 220px;
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                    margin: auto;
                  "
                >
                  <!-- <img
                    src="/assets/img/host/lang.svg"
                    class="icon"
                    style="width: 20px; top: 45%; right: 5%"
                  /> -->
                  <v-select
                    class="lang"
                    v-model="selectedLanguage"
                    :items="languageOptions"
                    :item-text="'name'"
                    :item-value="'code'"
                    @input="route_rdr"
                    height="40"
                    color="#fff"
                    background-color="#B0B0B036"
                    dark
                    :placeholder="$t('langs')"
                    single-line
                  >
                  <template v-slot:prepend-inner>
                    <span style="content: url('/assets/img/host/lang.svg'); width:20px; padding-top: 6px; margin: 0 7px;"></span>
                  </template>
                </v-select>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
      <v-col cols="12" md="9" lg="9" class="host_bac2">
        <div class="host_logo">
          <img src="assets/img/host/logo.png" style="width:80px;height: :80px;" alt="" />
        </div>
        <div class="host_form" pt-3>
          <p>{{ $t('host_form_disc') }}</p>
        </div>
        <v-row>
          <v-col cols="12" md="10" style="margin: 0 auto">
            <div class="host_con">
              <div class="host_title">
                <p>
                  
                  {{ instructions[0].title }}
                </p>
              </div>
              <div class="host_info">
      

                <p class="tl" style="white-space: pre;" v-html="bbcode(instructions[0].details)"></p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="10" style="margin: 0 auto">
            <div
              class="mb-5"
              style="
                width: 100%;
                margin: 0 auto;
                display: flex;
                justify-content: end;
              "
            >
              <a
                class="btn_booking"
                :href="'https://alkafeel.net/host_form?lang=' + $i18n.locale"
                >الحجز</a
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import bbCodeParser from 'js-bbcode-parser';
export default {
  data: () => ({
    items: [],
    // cities: ["كربلاء", "بغداد", "البصرة", "النجف"],
    cities: [],
    countries: [],
    arrayDates: [],
    languageOptions: ["العربية", "الفارسية", "الانكليزية"],
    arrayDates: ['2023-09-20', '2020-08-21', '2020-08-25', '2020-09-02'],
    selectedLanguage: "العربية",
    languageOptions: [],
    add_amenity: "",

    data: {
      is_delivery: 0,

      is_iraqi: 1,
      fullname: "",
      phone: "",
      card: "",
      face: "",
      city_id: "",
      country_id: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      amenity: [{ name: "", card_no: "", face: "" }],
    },

    selectedOptionType: "option1",
    selectedOptionCountries: "option1",
    showSelect1: true,
    showSelect2: false,
    showSelectdelivery: true,
    showSelectSafry: false,
    instructions:[],
    menu: false,
    pass_file: null,
    passLoading: false,
    national_id: true,
    idLoading1: false,
    idLoading2: false,
    file_font: null,
    file_back: null,
    activate_pass: true,
    activate_id: true,
    passLoading_: [{'f':false},{'f':false},{'f':false},{'f':false},{'f':false}],
    idLoading_: [{'f':false},{'f':false},{'f':false},{'f':false},{'f':false}],
    file_back_m: null,

    mix_file: null,
    show_back: [{'b':false},{'b':false},{'b':false},{'b':false},{'b':false}],
    show_name: [{'b':false},{'b':false},{'b':false},{'b':false},{'b':false}],
    
    required: [{'b':false},{'b':false},{'b':false},{'b':false},{'b':false}],
    rules: {required: value => !!value || "هذا الحقل مطلوب",},

    file_front_m: null,
    logo: 'logo6.svg',
    lang: 'ar',
    dir: 'rtl',

    disable_btn: false,
    loading: false
  }),

  created() {
    this.getinstructions()
    this.getbookingDates();
    this.get_cities();
    this.get_countries();
    this.get_langs();
    
  },
  watch: {
    // '$route' (to,from) {
      // console.log(to.query.lang);
      // store.commit('getI18nData', to.query.lang);
    // }
  },
  updated() {
    this.lang_dt(this.$route.query.lang);
    document.title = this.$t('host_form_disc');
  },
  methods: {
    route_rdr (code) {
      location.replace(location.protocol + '//' + location.host + location.pathname+'?lang='+code);
    },
    lang_dt (url) {
      if (url === undefined) {
          this.change_lang ('ar');
          return;
      }
      this.change_lang (url)
    },
    change_lang (code) {
      // console.log(code);
      this.languageOptions.forEach(element => {
          if (element.code === code) {

            this.lang = code;
            this.logo = element.logo;
            this.dir = element.dir == '1' ? 'rtl' : 'ltr';
            this.$store.dispatch("UpdateLang",{lang:this.lang,dir:this.dir});

              // if (this.lang != 'ar') {
              //   this.$store.commit('getI18nData', this.lang);
              // } else {
              //   location.replace(location.protocol + '//' + location.host + location.pathname);
              // }

              this.$vuetify.rtl = this.dir === 'rtl' ? true : false;
          }
      });
    },
    get_langs () {
      this.$axios.get("https://alkafeel.net/main_bg/data/core_langs_main.json")
      .then((res) => {
        this.languageOptions = res.data
      });
    },


    getinstructions () {
      this.$axios.get("https://alkafeel.net/alkafeel_admin_jobs/alkafeel_back_test/api/v1/host/instructions")
      .then((res) => {
        this.instructions = res.data
      });
    },

    allowedDates(val) {
      for (var i = 0; i < this.arrayDates.length; i++) {
        if (this.arrayDates[i] == val){
          return val
        } 
      }
    },
    getbookingDates(){
      this.$axios.get("host/bookingDates", {}).then((res) => {
        this.arrayDates = res.data.data;
      });
    },
    addInput() {
      if (this.data.amenity.length < 5) {
        this.data.amenity.push({ name: "", card_no: "", face: "" });
      }
    },
    removeInput(index) {
      this.data.amenity.splice(index, 1);
    },
    get_cities() {
      this.$axios.get("host/city", {}).then((res) => {
        this.cities = res.data.data;
      });
    },
    get_countries() {
      this.$axios.get("host/country", {}).then((res) => {
        this.countries = res.data;
      });
    },
    bbcode (x) {
                return bbCodeParser.parse(x);
            },
            stripHTML (value) {
                const div = document.createElement('div')
                div.innerHTML = value
                const text = div.textContent || div.innerText || ''
                return text
                // return value.replace(/(<\/?(?:a|ol|ul|li|table|colgroup|col|tr|th|td|tbody|em|strong|s|span|img)[^>]*>)|<[^>]+>/ig, '$1');
            },
    send() {
      if (this.$refs.form.validate()) {
        this.loader = "loading";
        this.$axios({
          method: "post",
          url: "host",
          data: this.data,
        }).then((res) => {
          if (res.data.status) {
            res;
            this.data = {};
            this.$refs.form.reset();
            this.data= {
              is_delivery: false,
              pass_file: null,
              file_front_m: null,
              file_back_m: null,
              mix_file: null,
              file_font: null,
              file_back: null,
              is_iraqi: true,
              fullname: "",
              phone: "",
              card: "",
              face: "",
              city_id: "",
              country_id: "",
              date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
              amenity: [{ name: "", card_no: "", face: "" }],
            },
            
            this.passLoading_ = [{'f':false},{'f':false},{'f':false},{'f':false},{'f':false}],
            this.idLoading_ = [{'f':false},{'f':false},{'f':false},{'f':false},{'f':false}],
            this.show_back = [{'b':false},{'b':false},{'b':false},{'b':false},{'b':false}],
            this.show_name = [{'b':false},{'b':false},{'b':false},{'b':false},{'b':false}],
            this.required = [{'b':false},{'b':false},{'b':false},{'b':false},{'b':false}],
            this.passLoading = false,
            this.idLoading1 = false,
            this.idLoading2 = false,
            this.national_id = true,
            
            Swal.fire({
              position: "center",
              icon: "success",
              title: res.data.msg,
              imageUrl: res.data.qrcode,
              imageWidth: 300,
              imageHeight: 300,
              html: 'الرجاء الاحتفاظ بالصورة واظهارها عند الوصول <a download href="'+res.data.qrcode+'">تحميل</a>',
              showConfirmButton: false,
              // timer: 3000,
            });

            this.restdata()
          } else {
            if (res.data.targetDate) {
              Swal.fire({
                position: "center",
                showConfirmButton: true,
                timer: 6000,
                title: 'غير مسموح ',
                text: res.data.targetDate+" اخر تسجيل لك لم يتجاوز الشهرين يجب الحجز بعد التاريخ ",

                icon: 'warning',
              });
            } else {
              Swal.fire({
                position: "center",
                showConfirmButton: true,
                timer: 6000,
                title: 'غير مسموح ',
                text: res.data.msg,

                icon: 'warning',
              });
            }
            
          // this.$refs.form.reset();
          }
          
        });
      }
    },
    restdata(){
      this.data= {
      is_delivery: 0,
      is_iraqi: 1,
      fullname: "",
      phone: "",
      card: "",
      city_id: "",
      country_id: "",
      pass_file: null,
      file_front_m: null,
      file_back_m: null,
      mix_file: null,
      file_font: null,
      file_back: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      amenity: [{ name: "", card_no: "", face: "" }],
    }
    },
    setDelivery(value) {
      this.data.is_delivery = value;
      // console.log(this.data.is_delivery);
    },
    setIraqi(value) {
      this.data.is_iraqi = value;
      // console.log(this.data.is_iraqi);
    },
    cardRange(value) {
      const firstFourDigits = value.substr(0, 4);
      const firstFourDigitsInt = parseInt(firstFourDigits);

      const min = 1960; // Replace with your desired minimum value
      const max = 2023; // Replace with your desired maximum value
      return firstFourDigitsInt >= min && firstFourDigitsInt <= max;
    },
    isValidPhoneNumber(value) {
      const pattern = /^07\d{9}$/;
      return pattern.test(value);
    },
    uploadPass(file, index = null) {
      if (index === null) {
        this.passLoading = true;
      } else {
        this.passLoading_[index].f = true;
      }
      this.disable_btn = true;
      this.loading = true;
      
      const headers = { 'Content-Type': 'multipart/form-data' };
      if (file) {
        this.pass_file = file;
        const formData = new FormData();
        formData.append('front', this.pass_file);
        this.$axios.post('host/upload_pass', formData, { headers })
        .then((res) => { 
          this.disable_btn = false;
          this.loading = false;
          if (res.data.success) {
            if (index === null) {
              this.passLoading = false;
              this.activate_pass = false,
              this.data.face = res.data.data.face,
              this.data.card = res.data.data.number,
              this.$axios.get('host/country_id/'+res.data.data.country, {'code':res.data.data.country})
              .then((s) => {
                // console.log(s.data);
                this.data.country_id = s.data
              });
            } else {
              this.data.amenity[index].card_no = res.data.data.number,
              this.data.amenity[index].face = res.data.data.face,
              this.data.amenity[index].name = res.data.data.name,
              this.passLoading_[index].f = false;
            }
          } else {
            Swal.fire({
                title: res.data.data,
                text: "",
                icon: "error",
                timer: 5000,
                confirmButtonColor: 'red',
            })
          }
          
        }).catch(() => {
          Swal.fire({
            title: 'الرجاء التحقق من الصورة والمحاولة مجددا',
            text: "",
            icon: "error",
            timer: 5000,
            confirmButtonColor: 'red',
          })
        });
      }
    },

    uploadId(file, type, index = null) {
      if (index !== null) {
        this.idLoading_[index].f = true;
        this.file_back_m = file
      } else {
        if (type == 1) {
          this.idLoading1 = true;
          this.file_font = file
        } else {
          this.idLoading2 = true;
          this.file_back = file
        }
      }

      this.disable_btn = true;
      this.loading = true;

      const headers = { 'Content-Type': 'multipart/form-data' };
      if (index === null) {
        if (this.file_font && this.file_back) {
          const formData = new FormData();
          formData.append('front', this.file_font);
          formData.append('back', this.file_back);
          this.$axios.post('host/upload_id', formData, { headers })
          .then((res) => {
            this.disable_btn = false;
            this.loading = false;

            this.idLoading1 = false;
            this.idLoading2 = false;
            if (res.data.success) {
              this.activate_id = false,
              this.data.face = res.data.data.face,
              this.national_id = res.data.data.national_id,
              this.national_id ? this.data.card = res.data.data.number : ''
            } else {
              Swal.fire({
                  title: 'الرجاء التحقق من الصورة والمحاولة مجددا',
                  text: "",
                  icon: "error",
                  timer: 5000,
                  confirmButtonColor: 'red',
              })
            }
            
          }).catch(() => {
            Swal.fire({
              title: 'الرجاء التحقق من الصورة والمحاولة مجددا',
              text: "",
              icon: "error",
              timer: 5000,
              confirmButtonColor: 'red',
            })
          });
        }
      } else {
        if (this.file_back_m && this.file_front_m) {
          const formData = new FormData();
          formData.append('front', this.file_front_m);
          formData.append('back', this.file_back_m);
          this.$axios.post('host/upload_id', formData, { headers })
          .then((res) => {
            this.disable_btn = false;
            this.loading = false;

            this.idLoading_[index].f = false;
            this.passLoading_[index].f = false;
            if (res.data.success) {
              if (res.data.data.national_id == true) {
                // this.data.card = res.data.data
                this.data.amenity[index].card_no = res.data.data.number,
                this.data.amenity[index].face = res.data.data.face,
                this.data.amenity[index].name = res.data.data.name
              } else {
                this.data.amenity[index].face = res.data.data.face,
                this.show_back[index].b = false,
                this.show_name[index].b = true,
                this.required[index].b = true
              } 
            } else {
              return Swal.fire({
                  title: 'الرجاء التحقق من الصورة والمحاولة مجددا',
                  text: "",
                  icon: "error",
                  timer: 5000,
                  confirmButtonColor: 'red',
              })
            }
            
          }).catch(() => {
            Swal.fire({
              title: 'الرجاء التحقق من الصورة والمحاولة مجددا',
              text: "",
              icon: "error",
              timer: 5000,
              confirmButtonColor: 'red',
            })
          });
        }
      }
    },



    uploadMix(file, index) {
      this.disable_btn = true;
      this.loading = true;

      this.passLoading_[index].f = true;

      const headers = { 'Content-Type': 'multipart/form-data' };
      if (file) {
        this.mix_file = file;
        const formData = new FormData();
        formData.append('front', this.mix_file);
        this.$axios.post('host/upload_mix', formData, { headers })
        .then((res) => { 
          if (res.data.success) {

            this.disable_btn = false;
            this.loading = false;
            
            this.data.amenity[index].card_no = res.data.data.number,
            this.data.amenity[index].face = res.data.data.face,
            this.data.amenity[index].name = res.data.data.name,
            this.passLoading_[index].f = false
            
          // } else if (res.data.data.type === 'TD1') {
          //   alert(res.data.data.type);
          //   this.file_front_m = file,
          //   this.show_back[index].b = true
          } else {
            
            if (res.data.data.type == 'TD1') {
              this.file_front_m = file,
              this.show_back[index].b = true
            } else {
              Swal.fire({
                title: 'الرجاء التحقق من الصورة والمحاولة مجددا',
                text: "",
                icon: "error",
                timer: 5000,
                confirmButtonColor: 'red',
              })
            }
          }
          
        }).catch(() => {
          Swal.fire({
            title: 'الرجاء التحقق من الصورة والمحاولة مجددا',
            text: "",
            icon: "error",
            timer: 5000,
            confirmButtonColor: 'red',
          })
        });
      }
    },

    
  },
  computed: {
    phoneValidation() {
      return [
        (v) => !!v || "هذا الحقل مطلوب",
        (v) => /^\d+$/.test(v) || "رقم الهاتف يجب ان يحتوي ارقام فقط",
        (v) => /^\d{11}$/.test(v) || "رقم الهاتف يجب ان يحتوي على 11 رقم ",
        value => this.isValidPhoneNumber(value) || 'رقم هاتف غير صالح',
      ];
    },
    cardValidation() {
      if (this.data.is_iraqi == true){
        return [
        (v) => !!v || "هذا الحقل مطلوب",
        (v) => /^\d+$/.test(v) || "رقم الهوية يجب ان يحتوي ارقام فقط",
        // value => this.cardRange(value) || 'يجب اختيار رقم هوية صحيح',
      ];
      }
      else{
        return [
        (v) => !!v || "هذا الحقل مطلوب",
      ];
      }
    },
    national_idValidation () {
      return [
        (v) => !!v || "هذا الحقل مطلوب",
      ];
    },
    amenityNameValidation() {
      if (this.data.amenity.length > 1) {
        return [(v) => !!v || "هذا الحقل مطلوب"];
      } else {
        return [];
      }
    },
    cardNoValidation() {
      if (this.data.amenity.length > 1) {
        // return [(v) => !!v || "هذا الحقل مطلوب"];
        if (this.data.is_iraqi == true){
        return [
        (v) => !!v || "هذا الحقل مطلوب",
        (v) => /^\d+$/.test(v) || "رقم الهوية يجب ان يحتوي ارقام فقط",
        value => this.cardRange(value) || 'يجب اختيار رقم هوية صحيح',
      ];
      }
      else{
        return [
        (v) => !!v || "هذا الحقل مطلوب",
      ];
      }
      } else {
        return [];
      }
    },

    stringValidation() {
      return [
        (v) => !!v || "هذا الحقل مطلوب",
        (v) => (v && v.length <= 50) || " هذا الحقل يجب ان لا يتجاوز 50 حرف",
      ];
    },
    selectRules() {
      return [(v) => !!v || "هذا الحقل مطلوب"];
    },
  },
};
</script>

<style>

@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
.host_bac1 {
  background-image: url("/assets/img/host/Rectangle1.png");
  background-size: cover;
  height: auto;
}
@media screen and (max-width: 960px) {
  .host_bac1 {
    height: 100vh;
    background-position: center;
  }
}
/* Styles for the arrow icon */
.input-container {
  position: relative;
  /* width: 200px; */
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.host_abbas h4,
.host_abbas p {
  color: #ffffff;
  font-family: Almarai !important;
}

.lang_icon input {
  background-image: url("/assets/img/host/lang.svg");
  background-size: 20px 20px;
  background-position: right center;
  background-repeat: no-repeat;
  padding-right: 30px;
}

.lang_icon
  .v-input__control
  .v-input__slot
  .v-select__slot
  .v-select__selections {
  padding-right: 30px !important;
  direction: ltr;
}
.host_bac2 {
  background-image: url("/assets/img/host/Rectangle2.png");
  background-size: cover;
  /* height: 100vh; */
  margin: 0 auto;
  padding: 0;
  overflow: scroll;
}
.host_logo {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.host_con {
  height: auto;
  /* max-width: 900px; */
  /* overflow-y: scroll; */
  width: 100%;
  margin: 0 auto;
  background: #ffffff80;
  border-radius: 8px;
  padding: 10px 30px 30px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
}
.host_con .host_title {
  padding: 50px 20px 0;
}
.host_con .host_info {
  padding: 10px 30px 30px 30px;
}
.host_con .host_title p {
  font-family: Almarai;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
  font-size: 20px;
  font-style: normal;
}
.host_con .host_info p {
  font-family: Almarai !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: right;
  color: #828282;
  margin-bottom: 0;
}
.host_con .host_info span {
  color: #01652f;
  font-size: 20px;
  font-weight: 700;
}
.btn_booking {
  background-color: #01652f;
  margin-top: 20px;
  min-width: 200px;
  text-align: center;
  text-decoration: none;
  width: 193.00352478027344px;
  height: 40px;
  border-radius: 8px;
}
.v-application a {
  color: #fff !important;
  font-family: Almarai;
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
}

.lang_icon input::placeholder,
.lang input::placeholder {
  font-family: Almarai;
}

.input-wrapper {
  position: relative;
}

.icon {
  position: absolute;
  top: 34%;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 20px;
}
.lang .v-select__selections {
  margin-right: 48px;
}

@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
.host_bac1 {
  background-image: url("/assets/img/host/Rectangle1.png");
  background-size: cover;
  height: auto;
}
@media screen and (max-width: 960px) {
  .host_bac1 {
    height: 60vh;
    background-position: center;
  }
}

.host_abbas h4,
.host_abbas p {
  color: #ffffff;
  font-family: Almarai !important;
}
.send_host {
  color: #fff !important;
  font-family: Almarai;
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
}

.host_bac2 {
  /* background-image: url("/assets/img/host/Rectangle2.png"); */
  background: url("/assets/img/host/Rectangle2.png"),
    lightgray 50% / cover no-repeat;
  background-size: cover;
  /* height: 100vh; */
  min-height: 990px;
  margin: 0 auto;
  /* padding: 0; */
  overflow-y: scroll;
}
.host_form {
  display: flex;
  font-size: 22px !important;
  justify-content: center;
}
.host_form p {
  color: #2f2f2f;
  text-align: right;
  font-family: Almarai;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  padding-top: 10px;
  line-height: 34px; /* 212.5% */
}
.host_logo {
  display: flex;
  justify-content: center;
  /* margin: 20px 0; */
}
.host_con {
  height: auto;
  /* max-width: 900px; */
  /* overflow-y: scroll; */
  width: 100%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  padding: 10px 30px 30px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
}
.host_con .host_title {
  padding: 50px 20px 0;
}
.host_con .host_info {
  padding: 10px 30px 30px 30px;
}
.host_con .host_title p {
  font-family: Almarai;
  font-size: 30px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}
.host_con .host_info p {
  font-family: Almarai;
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: right;
  color: #828282;
  margin-bottom: 0;
}

.host_con .host_info span {
  color: #01652f;
  font-size: 20px;
  font-weight: 700;
  font-family: Almarai;
}

.type_booking h3 {
  color: #01652f;
  font-family: Almarai;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  /* text-align: right; */
  padding-right: 16px;
}

.loction_btn {
  font-family: Almarai !important;
  width: 100%;
  height: 50px;
  color: #000;
  font-size: 18px;
  /* padding: 5px 10px;
  margin: 4px; */
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 5px;
  border: 1px #c8c8c8 solid;
  box-shadow: 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  margin: 0 !important;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: right;
}

.active_btn {
  background-color: #484848;
  color: #fff;
}

.label_input {
  color: #01652f;
  font-family: Almarai !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: right;
}
.add_amenity {
  font-family: Almarai !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: right;
  color: #9b9b9b !important;
  text-decoration: none;
}
.lang_icon input {
  background-image: url("/assets/img/host/lang.svg");
  background-size: 20px 20px;
  background-position: right center;
  background-repeat: no-repeat;
  /* padding-right: 30px; */
}
.user_icon input {
  /* background-image: url("/assets/img/host/user.svg"); */
  background-size: 20px 20px;
  background-position: right center;
  background-repeat: no-repeat;
  /* padding-right: 30px; */
}
.date_icon input {
  /* background-image: url("/assets/img/host/calendar\ 1.svg"); */
  background-size: 20px 20px;
  background-position: right center;
  background-repeat: no-repeat;
  /* padding-right: 30px; */
}
.phone_icon input {
  /* background-image: url("/assets/img/host/smartphone\ 1.svg"); */
  background-size: 20px 20px;
  background-position: right center;
  background-repeat: no-repeat;
  /* padding-right: 30px; */
}
.card_icon input {
  /* background-image: url("/assets/img/host/credit-card\ 2.svg"); */
  background-size: 20px 20px;
  background-position: right center;
  background-repeat: no-repeat;
  /* padding-right: 30px; */
}


.lang .v-select__selections {
  margin-right: 48px;
}
.lang_icon .v-input__control .v-input__slot .v-select__slot input:focus {
  max-width: min-content !important;
}
.amenity_icon input {
  background-image: url("/assets/img/host/user.svg");
  background-size: 20px 20px;
  background-position: right center;
  background-repeat: no-repeat;
  /* padding-right: 30px; */
}
.cardNo_icon input {
  /* background-image: url("/assets/img/host/credit-card\ 2.svg"); */
  background-size: 20px 20px;
  background-position: right center;
  background-repeat: no-repeat;
  /* padding-right: 30px; */
}
.user_icon input::placeholder,
.date_icon input::placeholder,
.phone_icon input::placeholder,
.card_icon input::placeholder,
.amenity_icon input::placeholder,
.cardNo_icon input::placeholder,
.lang_icon input::placeholder,
.city_icon input::placeholder,
.v-file-input__text--placeholder,
.lang input::placeholder {
  font-family: Almarai;
  font-weight: bold;
  color: #7d7d7d !important;
  /* justify-self: end !important; */
}
.theme--dark .user_icon input,
.theme--dark .date_icon input,
.theme--dark .phone_icon input,
.theme--dark .card_icon input,
.theme--dark .amenity_icon input,
.theme--dark .cardNo_icon input,
.theme--dark .city_icon input {
  color: hsl(0, 0%, 0%) !important;
}

.theme--dark .loction_btn {
  background-color: #ffffff !important;
  color: #000000;
}

.theme--dark .active_btn {
  background-color: #4f4949 !important;
  color: #fff;
}
.loction_btn {
  background-color: #ffffff !important;
  color: #000000;
}

.active_btn {
  background-color: #4f4949 !important;
  color: #fff !important;
}

.input-wrapper {
  position: relative;
}

.icon {
  position: absolute;
  top: 34%;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 20px;
}
</style>
